import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Flyers, Projects } from './Projects';

let publicUrl = process.env.PUBLIC_URL + '/'

const ProjectThree = () => {
    const items = [
        {
            image: publicUrl + "assets/images/update1.0/project-v3-img1.JPG",
            name: "En vente DUPLEX 04 PIÈCES ANGRE",
            description: `
                Toutes les chambres sont autonomes
                <br/>80 MILLIONS 
                <br/>DOCUMENT: ACD
                <br/>SUPERFICIE : 200 M
                <ul class="ps-4">
                    <li style="list-style: circle;">Une toilette visiteur</li>
                    <li style="list-style: circle;">Une salle à manger</li>
                    <li style="list-style: circle;">une cour avant et arrière</li>
                    <li style="list-style: circle;">Un garage de 2 voitures</li>
                </ul>
            `
        },
        {
            image: publicUrl + "assets/images/update1.0/project-v3-img2.JPG",
            name: "Duplex en vente à Cocody rivièra PALMERAIE",
            description: `
                Duplex 4piec et 5piec +garage bâtit sur 200m2
                <br/>Prix : 4piec 65millions et 5pieces 75millions 
                <br/>Doc :ACD 
            `
        },
        {
            image: publicUrl + "assets/images/update1.0/project-v3-img3.JPG",
            name: "VILLA À VENDRE Riviera Palmeraie • 450m2",
            description: `
                7 pièces
                <ul class="ps-4">
                    <li>4 salles de bains</li>
                    <li>2 salons</li>
                    <li>Une grande salle à manger</li>
                    <li>Cuisine aménagée</li>
                    <li>4 Chambres autonomes</li>
                    <li>1 chambre visiteurs</li>
                    <li>2 cours à l'avant et l'arrière de la maison</li>
                    <li>Garage</li>
                </ul>
            `
        },
        {
            image: publicUrl + "assets/images/update1.0/project-v3-img4.JPG",
            name: "Duplex  en vente à ANGRÉ NOUVEAU CHU DJOROBITÉ 1",
            description: `
                Superficie : 200m2 - 250m2 - 300m2
                <br/>Document Disponible : ACD
            `
        },
        {
            image: publicUrl + "assets/images/update1.0/project-v3-img5.JPG",
            name: "Triplex EN VENTE Route Abatta",
            description: `
                Superficie: 200m2
                <br/>Document: CMPF
                <br/>CARACTERISTIQUES ( 7 PIECES )
                <br/>5 chambres autonomes
                <br/>2 buanderies
                <br/>2 salons 
                <br/>Grand préau
                <br/>2 toilettes visiteurs v Cour + garage
            `
        },
        {
            image: publicUrl + "assets/images/update1.0/project-v3-img6.JPG",
            name: "Triplex À Vendre à La Palmeraie / St-Viateur",
            description: `  
                Rez-de-chaussée :
                Deux salons spacieux;
                Deux chambres confortables;
                Deux WC visiteurs modernes;
                Une cuisine équipée;

                <br/>1er étage :
                Un salon convivial;
                Cinq chambres lumineuses;

                <br/>2ème étage :
                Trois chambres élégantes;
                Un salon luxueux ;
                Une piscine privée; 
                Une terrasse spacieuse; 
                Une salle;
            `
        },
    ]
    const [data, setData] = useState(items)

    const flyers = [
        {
            image: publicUrl + "assets/images/update1.0/flyer.jpg",
            name: "Titre...",
            description: "Description..."
        },
        {
            image: publicUrl + "assets/images/update1.0/flyer3.JPG",
            name: "Titre...",
            description: "Description..."
        },
        {
            image: publicUrl + "assets/images/update1.0/flyer4.JPG",
            name: "Titre...",
            description: "Description..."
        },
        {
            image: publicUrl + "assets/images/update1.0/flyer6.JPG",
            name: "Titre...",
            description: "Description..."
        },
    ]
    const [data2, setData2] = useState(flyers)

    const fetchData = async () => {
        try {
            const res = await fetch('http://localhost:8077/api/projects')
            const result = await res.json()
            setData(result)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <section id="projects" className="project-three text-dark">
            <div className="container-fluid">
                <div className="sec-title text-center">
                    <div className="sec-title__tagline">
                        <span className="left"></span>
                        <h6>Offres</h6> <span className="right"></span>
                    </div>
                    <h2 className="sec-title__title">Nos offres immobiliers</h2>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <Projects data={data} />
                        <Flyers data={data2} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectThree














