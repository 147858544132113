import React, { useEffect, useState } from 'react';

export const GaleryUniqueItem = ({ data }) => {
    const {
        title,
        subtitle,
        description,
        icon,
        cover
    } = data

    return (
        <div className="col-xl-3 position-relative">
            {cover ?
                <div className="feature-four__single">
                    <div className="feature-four__single-img">
                        <img src={cover} alt="#" />
                    </div>
                </div>
                :
                <div className="feature-four__single style2 text-center w-100 p-4">
                    {icon &&
                        <div className="feature-four__single-icon">
                            <img src={icon} alt="#" />
                        </div>
                    }
                    <div className="feature-four__single-content">
                        {/* <span>{subtitle}</span> */}
                        <h2 class="text-capitalize">{title}</h2>
                        <p className='text-start'>{description}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export const GaleryArrayItem = ({ data }) => {
    return (
        <div className="col-xl-3 position-relative">
            {data.map((item, index) => {
                const {
                    title,
                    subtitle,
                    description,
                    icon,
                    cover
                } = item

                return cover ?
                    <div key={index} className="feature-four__single">
                        <div className="feature-four__single-img">
                            <img src={cover} alt="#" />
                        </div>
                    </div>
                    :
                    <div key={index} className="feature-four__single style2 text-center w-100 p-4">
                        {icon &&
                            <div className="feature-four__single-icon">
                                <img src={icon} alt="#" />
                            </div>
                        }
                        <div className="feature-four__single-content">
                            <span>{subtitle}</span>
                            <h2>{title}</h2>
                            <p>{description}</p>
                        </div>
                    </div>
            })}
        </div>
    )
}
