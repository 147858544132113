import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';

function OffCanvasStyleOne() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        console.log("click......", show)
        setShow(true)
    };

    console.log("show......", show)

    return (
        <>
            <div className="side-content-button">
                <button
                    variant="primary"
                    className="btn btn-dark"
                    onClick={(e) => {
                        console.log("clickkkkkk......", show)
                        handleShow(e)
                    }}>
                    Nous contacter
                    <span className="decor"></span>
                    <span className="decor"></span>
                    <span className="decor"></span>
                </button>

                <Offcanvas show={show} onHide={handleClose} className="xs-sidebar-group info-group info-sidebar isActive">
                    <div className="xs-overlay xs-bg-black"></div>
                    <div className="xs-sidebar-widget">
                        <div className="sidebar-widget-container">
                            <Offcanvas.Header closeButton className="close-side-widget">
                            </Offcanvas.Header>
                            <div className="sidebar-textwidget px-4 py-3">
                                <div className="sidebar-info-contents">
                                    <div className="content-inner">
                                        <div className="logo col-5">
                                            <Link className='w-100 d-block' to="/">
                                                <img src="/assets/images/resources/K2AB_Groupe.jpg" alt="" style={{ width: "100%" }} />
                                            </Link>
                                        </div>
                                        <div className="content-box">
                                            <h4>Formulaire de contact</h4>
                                            <div className="inner-text">
                                                <p>Remplissez le formulaire ci-après puis cliquez sur "Soumettre"</p>
                                            </div>
                                        </div>

                                        <div className="form-inner">
                                            <form action="index.html" method="post">
                                                <div className="form-group">
                                                    <input type="text" name="name" placeholder="Nom" required="" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="email" name="email" placeholder="Email" required="" />
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" placeholder="Message..."></textarea>
                                                </div>
                                                <div className="form-group message-btn">
                                                    <button className="thm-btn" data-text="Soumettre" type="submit" data-loading-text="Please wait...">Soumettre</button>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="sidebar-contact-info">
                                            <h4>Contacts</h4>
                                            <ul>
                                                <li>
                                                    <span className=" icon-maps-and-flags"></span>
                                                    Riviera Faya, Cocody
                                                </li>
                                                <li>
                                                    <span className="icon-phone-call-1"></span>
                                                    <a href="tel:2722292074">2722292074 / 0711709793</a>
                                                </li>
                                                <li>
                                                    <span className="icon-email-1"></span>
                                                    <a href="mailto:info@example.com">info@example.com</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="thm-social-link1">
                                            <ul className="social-box">
                                                <li className="facebook">
                                                    <a href="https://www.facebook.com/people/Kaabe-Groupe-Immobilier/61553724592291"><i className="icon-facebook-app-symbol" aria-hidden="true"></i></a>
                                                </li>
                                                <li className="facebook">
                                                    <a href="https://www.facebook.com/people/Kaabe-Groupe-Immobilier/61553724592291">
                                                        <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                                                            <img style={{ width: 18 }} src="https://img.icons8.com/ios-filled/50/000000/whatsapp--v1.png" />
                                                        </div>
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="https://twitter.com"><i className="icon-twitter" aria-hidden="true"></i></a>
                                                </li>
                                                <li className="linkedin">
                                                    <a href="https://linkedin.com"><i className="icon-linkedin" aria-hidden="true"></i></a>
                                                </li>
                                                <li className="gplus">
                                                    <a href="https://google.com"><i className="icon-google-plus-logo" aria-hidden="true"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas>
            </div>
        </>
    );
}

export default OffCanvasStyleOne;