import React from 'react';
import { Link, useLocation } from "react-router-dom";

const Nav = () => {
    const { pathname } = useLocation();
    const URL = pathname.split("#")[0];

    return (
        <div className="main-menu text-center">
            <nav>
                <ul className="main-menu__list">
                    <li className="current">
                        <a href={URL + `#`}>Accueil</a>
                    </li>
                    <li className="">
                        <a href={URL + `#services`}>Nos services</a>
                    </li>
                    <li className="">
                        <a href={URL + `#features`}>Nos projets</a>
                    </li>
                    <li><a href={URL + `#projects`}>Nos offres</a></li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav;


