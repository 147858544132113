import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const Projects = ({ data }) => {

    useEffect(() => {
        // const $ = window.$;
        // if ($(".project-three__carousel").length) {
        //     $(".project-three__carousel").owlCarousel({
        //         loop: true,
        //         margin: 10,
        //         nav: false,
        //         smartSpeed: 500,
        //         autoHeight: false,
        //         autoPlay: true,
        //         dots: false,
        //         autoplayTimeout: 6000,
        //         navText: [
        //             '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
        //             '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
        //         ],
        //         responsive: {
        //             0: {
        //                 items: 1,
        //             },
        //             600: {
        //                 items: 1,
        //             },
        //             750: {
        //                 items: 2,
        //             },
        //             1100: {
        //                 items: 3,
        //             },
        //             1350: {
        //                 items: 4,
        //             },
        //         },
        //     });
        // }
        // if ($(".img-popup").length) {
        //     var groups = {};
        //     $(".img-popup").each(function () {
        //         var id = parseInt($(this).attr("data-group"), 10);

        //         if (!groups[id]) {
        //             groups[id] = [];
        //         }

        //         groups[id].push(this);
        //     });

        //     $.each(groups, function () {
        //         $(this).magnificPopup({
        //             type: "image",
        //             closeOnContentClick: true,
        //             closeBtnInside: false,
        //             gallery: {
        //                 enabled: true,
        //             },
        //         });
        //     });
        // }
    }, []);

    return (
        <div className="project-three__carousel owl-carousel mb-5 owl-theme row mx-auto d-flex justify-content-around display: flex !important flex-nowrap overflow-auto">
            {data.map(
                (item, index) =>
                (
                    <div key={index} className="project-three__single col-md-4">
                        <div style={{ height: "20em" }}
                            className="project-three__single-img">
                            <img src={item.image} alt="#" />
                            <div className="content-box">
                                {/* <p>constructionnn</p> */}
                                <h3>
                                    <Link className='d-flex' to={process.env.PUBLIC_URL + `/portfolio-details`}>
                                        Intéressé
                                        <span className="ps-2 icon-plus-sign"></span>
                                    </Link>
                                </h3>
                            </div>
                        </div>
                        <div className="content-box">
                            <h4 className='mt-3 my-2'>{item.name}</h4>
                            <p dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export const Flyers = ({ data }) => {

    useEffect(() => {
        // const $ = window.$;
        // if ($(".project-three__carousel").length) {
        //     $(".project-three__carousel").owlCarousel({
        //         loop: true,
        //         margin: 10,
        //         nav: false,
        //         smartSpeed: 500,
        //         autoHeight: false,
        //         autoPlay: true,
        //         dots: false,
        //         autoplayTimeout: 6000,
        //         navText: [
        //             '<i className="fa fa-angle-double-left" aria-hidden="true"></i>',
        //             '<i className="fa fa-angle-double-right" aria-hidden="true"></i>',
        //         ],
        //         responsive: {
        //             0: {
        //                 items: 1,
        //             },
        //             600: {
        //                 items: 1,
        //             },
        //             750: {
        //                 items: 2,
        //             },
        //             1100: {
        //                 items: 3,
        //             },
        //             1350: {
        //                 items: 4,
        //             },
        //         },
        //     });
        // }
        // if ($(".img-popup").length) {
        //     var groups = {};
        //     $(".img-popup").each(function () {
        //         var id = parseInt($(this).attr("data-group"), 10);

        //         if (!groups[id]) {
        //             groups[id] = [];
        //         }

        //         groups[id].push(this);
        //     });

        //     $.each(groups, function () {
        //         $(this).magnificPopup({
        //             type: "image",
        //             closeOnContentClick: true,
        //             closeBtnInside: false,
        //             gallery: {
        //                 enabled: true,
        //             },
        //         });
        //     });
        // }
    }, []);

    return (
        <div className="project-three__carousel owl-carousel owl-theme row mx-auto d-flex justify-content-around display: flex !important flex-nowrap overflow-auto">
            {data.map(
                (item, index) =>
                (
                    <div key={index} className="project-three__single w-auto text-center border d-block col-md-4 p-5">
                        <div style={{ height: "20em" }}
                            className="project-three__single-img w-auto">
                            <img
                                src={item.image} alt="#"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%"
                                }}
                            />
                            <div className="content-box">
                                <h3>
                                    <Link className='d-flex' to={process.env.PUBLIC_URL + `/portfolio-details`}>
                                        Intéressé
                                        <span className="ps-2 icon-plus-sign"></span>
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}