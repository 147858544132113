import React, { useEffect, useState } from 'react';

const AboutFour = () => {
    let publicUrl = process.env.PUBLIC_URL + '/'

    const items = [
        publicUrl + "assets/images/update1.0/about-v3-img3.JPG",
        publicUrl + "assets/images/update1.0/about-v3-img4.JPG",
        publicUrl + "assets/images/update1.0/about-v3-img5.JPG",
        publicUrl + "assets/images/update1.0/about-v3-img2.JPG",
    ]

    const [title, settitle] = useState("Projet Immobilier d'Exception à Abouabou, Derrière l'Aéroport")
    // const [subtitle, setsubtitle] = useState("")
    const [description, setdescription] = useState(`
        Situé à seulement quelques minutes de l'aéroport international, ce projet immobilier offre une opportunité unique pour les particuliers et les investisseurs à la recherche d'un cadre de vie moderne, sécurisé et accessible.

        Conçu pour répondre aux besoins de tous, en ciblant à la fois l’immobilier des couches moyennes et l'immobilier de luxe. Que vous recherchiez un logement abordable ou une résidence haut de gamme, nos duplexes spacieux et bien conçus sont parfaits pour répondre aux attentes de chacun.

        <br/>Avantages :
        <ul class="ps-4">
            <li style="list-style: circle;">Proximité stratégique avec l'aéroport et les principales voies d'accès</li>
            <li style="list-style: circle;">Infrastructures modernes</li>
            <li style="list-style: circle;">Sécurité 24/7 avec des services de surveillance</li>
            <li style="list-style: circle;">Duplexes élégants et fonctionnels, adaptés à tous les budgets</li>
            <li style="list-style: circle;">Espace de loisirs, écoles et centres commerciaux à proximité</li>
            <li style="list-style: circle;">Possibilité de financement et d’acquisition en plusieurs tranches</li>
        </ul>
    `)
    const [cover, setCover] = useState(publicUrl + "assets/images/update1.0/about-v3-img2.JPG")
    const [images, setimages] = useState(items)

    const fetchData = async () => {
        try {
            const res = await fetch('http://localhost:8077/api/website/section')
            const result = await res.json()
            if (!result) return
            const { title, description, medias, cover } = result
            settitle(title)
            setdescription(description)
            setimages(medias)
            if (cover) setCover(cover)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData()
    }, []);


    const initCarousel = () => {
        const $ = window.$;
        if ($(".video-popup").length) {
            $(".video-popup").magnificPopup({
                // items: images.map(i => ({ src: i })),
                // gallery: {
                //     enabled: true
                // },
                // type: 'image',
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: true,
                fixedContentPos: false,
            });
        }
    }

    useEffect(() => {
        initCarousel()
    }, [images]);

    return (
        <>
            <div className="about-three text-dark pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-three__img">
                                <div className="inner">
                                    <img src={cover} alt="#" />
                                    {/* <div className="about-three__img-video wow zoomIn" data-wow-delay="100ms">
                                        <button className="video-popup">
                                            <div className="about-three__img-video-icon">
                                                <span className="fa fa-play"></span>
                                                <i className="ripple"></i>
                                            </div>
                                        </button>
                                    </div> */}
                                    <div className="about-three__img-video wow zoomIn" data-wow-delay="100ms">
                                        <a href={publicUrl + 'assets/videos/about-v3.mp4'} className="video-popup">
                                            <div className="about-three__img-video-icon">
                                                <span className="fa fa-play"></span>
                                                <i className="ripple"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="about-three__content">
                                <div className="sec-title">
                                    <h2 className="sec-title__title">{title}</h2>
                                </div>

                                <div className="about-three__content-inner">
                                    <div className="text1">
                                        <div id="subscribe" dangerouslySetInnerHTML={{ __html: description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutFour