import React from 'react';
import { Link } from 'react-router-dom';

export default class FooterOne extends React.Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <footer className="footer-one-sec">
                    <div className="footer-one__pattern" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/pattern/footer-v1-pattern.png)' }}></div>
                    <div className="footer-one__top py-5">
                        <div className="container py-3">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__top-inner">
                                        <div className="row">
                                            <div className="col-xl-5 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                                                <div className="footer-widget__column d-flex footer-widget__about">
                                                    <div className="footer-widget__about-logo me-4">
                                                        <Link to="/"><img width={150} src={publicUrl + "assets/images/resources/K2AB_Groupe.jpg"} alt="Logo" /></Link>
                                                    </div>

                                                    <p className="footer-widget__about-text">
                                                        KAABE GROUPE IMMOBILIER, Votre Partenaire de Confiance pour Tous Vos Projets Immobiliers.
                                                    </p>
                                                </div>
                                                <div className="footer-widget__about-social-link">
                                                    <ul>
                                                        <li>
                                                            <a href="https://www.facebook.com/people/Kaabe-Groupe-Immobilier/61553724592291">
                                                                <span className="first icon-facebook-app-symbol"></span>
                                                                <span className="second icon-facebook-app-symbol"></span>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="https://api.whatsapp.com/send?phone=%2B2250711709793">
                                                                <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                                                                    <img style={{ width: 18 }} src="https://img.icons8.com/ios-filled/50/000000/whatsapp--v1.png" />
                                                                </div>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="https://instagram.com/kaabegroupeimmobilier_">
                                                                <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                                                                    <img style={{ width: 15 }} src="https://img.icons8.com/ios-filled/50/000000/instagram--v1.png" />
                                                                </div>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <a href="https://www.linkedin.com/in/kaabe-groupe-511457308/">
                                                                <span className="first icon-linkedin"></span>
                                                                <span className="second icon-linkedin"></span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="ms-auto col-xl-6 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
                                                <div className="footer-widget__column footer-widget__services">
                                                    <h2 className="footer-widget__title">Nos Services</h2>
                                                    <div className="row">
                                                        <ul className="col footer-widget__services-list">
                                                            <li className="footer-widget__services-list-item"><a href="/#services">Lotissement</a></li>
                                                            <li className="footer-widget__services-list-item"><a href="/#services">Construction</a></li>
                                                            <li className="footer-widget__services-list-item"><a href="/#services">Gestion immobilière</a></li>
                                                        </ul>
                                                        <ul className="col footer-widget__services-list">
                                                            <li className="footer-widget__services-list-item"><a href="/#services">Gestion locative</a></li>
                                                            <li className="footer-widget__services-list-item"><a href="/#services">Promotion immobilière</a></li>
                                                            <li className="footer-widget__services-list-item"><a href="/#services">Vente de matériaux de construction</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-one__bottom clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__bottom-inner">
                                        <div className="footer-one__bottom-text">
                                            <p>Copyright &copy;2024. Tous droits réservés <Link to="/">KAABE GROUPE IMMOBILIER</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}