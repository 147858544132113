import React, { useEffect, useState } from 'react';
import { GaleryArrayItem, GaleryUniqueItem } from './Feature';

let publicUrl = process.env.PUBLIC_URL + '/'

const FeatureFour = () => {
    const items = [
        [
            {
                title: "Titre...",
                description: "Description...",
                subtitle: "Sous-titre...",
                icon: publicUrl + "assets/images/update1.0/feature-v3-img1.jpg",
                cover: publicUrl + "assets/images/update1.0/feature-v3-img1.jpg",
            },
            {
                title: "Titre...",
                description: "Description...",
                subtitle: "Sous-titre...",
                icon: publicUrl + "assets/images/update1.0/feature-v3-img2.JPG",
                cover: publicUrl + "assets/images/update1.0/feature-v3-img2.JPG",
            },
        ],
        {
            subtitle: "Sous-titre...",
            title: "projet de construction  ",
            description: "Un complexe résidentiel moderne. Les travaux incluront des équipements de haute qualité et des infrastructures écologiques, visant à créer un environnement de vie confortable et durable pour les futurs résidents",
        },
        [
            {
                title: "Titre...",
                description: "Description...",
                subtitle: "Sous-titre...",
                icon: publicUrl + "assets/images/update1.0/feature-v3-img4.JPG",
                cover: publicUrl + "assets/images/update1.0/feature-v3-img4.JPG",
            },
            {
                title: "Titre...",
                description: "Description...",
                subtitle: "Sous-titre...",
                icon: publicUrl + "assets/images/update1.0/feature-v3-img3.jpg",
                cover: publicUrl + "assets/images/update1.0/feature-v3-img3.jpg",
            },
        ],
        {
            title: "projet de lotissement ",
            description: "Respectueuses de l'environnement, ce projet comprend des terrains viabilisés et des infrastructures modernes, tels que des routes pavées, des réseaux d'eau et d'électricité, et des espaces verts aménagés.",
        },
        [
            {
                title: "Titre...",
                description: "Description...",
                subtitle: "Sous-titre...",
                icon: publicUrl + "assets/images/update1.0/feature-v3-img6.JPG",
                cover: publicUrl + "assets/images/update1.0/feature-v3-img6.JPG",
            },
            {
                title: "Titre...",
                description: "Description...",
                subtitle: "Sous-titre...",
                icon: publicUrl + "assets/images/update1.0/feature-v3-img7.jpg",
                cover: publicUrl + "assets/images/update1.0/feature-v3-img7.WEBP",
            },
        ],
        {
            title: "TRAVAUX PUBLIQUES",
            description: " L'entreprise Kaabe Groupe Immobilier réalise des travaux publics d'envergure, incluant la construction de routes, ponts, et infrastructures urbaines, en privilégiant des solutions durables et de haute qualité",
        },
    ]
    const [data, setData] = useState(items)

    const fetchData = async () => {
        try {
            const res = await fetch('http://localhost:8077/api/gallery/items')
            const result = await res.json()
            setData(result)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    if (!data.length) return

    return (
        <>
            {/* TODO Photo avec un client décrivant son projet, photo avec la maison du client, photo avec un terrain */}
            {/* TODO Formulaire de contact pour permettre au client de laisser un message */}

            <section id="features" className="feature-four text-dark">
                <div className="container">
                    <div className="sec-title text-center">
                        <h4 className="sec-title__title">Nos projets achevés et <br /> en cours.</h4>
                    </div>
                    <div className="row d-flex flex-nowrap overflow-auto">
                        {data.map((item, index) => {
                            return Array.isArray(item) ?
                                <GaleryArrayItem key={index} data={item} />
                                :
                                <GaleryUniqueItem key={index} data={item} />
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeatureFour;